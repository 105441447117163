 <template>
  <div>
    <div class="card">
      <div class="card-body">
        <h5 class="mb-4">
          <strong>Detail Voucher</strong>
        </h5>
        <a-spin v-if="isLoading" />
        <a-form :form="form"  @submit="handleSubmit" v-if="!isLoading">
          <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Kode Voucher">
            <a-input
              :disabled="submitting"
              placeholder="Masukkan Kode Voucher"
              v-decorator="['code', {rules: [{ required: true, message: 'Kode Voucher harus diisi!' }]}]"
            />
          </a-form-item>
          <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Jenis Test Laboratorium">
            <a-select
              :disabled="submitting"
              v-decorator="[
                'testLabTypeId',
                { rules: [{ required: true, message: 'Jenis Test Laboratorium harus diisi!' }] },
              ]"
              placeholder="Jenis Test Laboratorium"
            >
              <a-select-option v-for="testlabType in testlabTypeList" :key="testlabType.id" :value="testlabType.id">
                  {{testlabType.name}}
               </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Status">
            <a-select
              :disabled="submitting"
              v-decorator="[
                'status',
                { rules: [{ required: true, message: 'Status harus diisi!' }] },
              ]"
              placeholder="Status"
            >
              <a-select-option v-for="status in statusList" :key="status.id" :value="status.id">
                  {{status.name}}
               </a-select-option>
            </a-select>
          </a-form-item>
          <button type="submit" class="btn btn-success px-5" :disabled="submitting">Simpan Data</button>
          <button class="btn btn-danger px-5 m-1" :disabled="submitting" @click="(e)=>{e.preventDefault(); setModalVisible(true)}">Hapus Data</button>
          <button class="btn btn-secondary px-5" :disabled="submitting" @click="handleCancel">Kembali</button>
        </a-form>
      </div>
    </div>
    <a-modal
      title="Konfirmasi"
      :dialog-style="{ top: '20px' }"
      :visible="modalVisible"
      @ok="() => handleDelete()"
      @cancel="() => setModalVisible(false)"
    >
      Apakah Anda yakin akan menonaktifkan data voucher {{form.getFieldValue("code")}}?
    </a-modal>
  </div>
</template>
<script>

import router from '@/router'
import { getById, editById, deleteById } from '@/services/axios/api/voucher'
import { getAll } from '@/services/axios/api/testlabtype'
import { notification } from 'ant-design-vue'

const labelCol = {
  xs: { span: 24 },
  sm: { span: 4 },
}
const wrapperCol = {
  xs: { span: 24 },
  sm: { span: 12 },
}
export default {
  data: function () {
    return {
      form: this.$form.createForm(this),
      labelCol,
      wrapperCol,
      testlabTypeList: [],
      isLoading: true,
      submitting: false,
      statusList: [{ id: 1, name: 'Aktif' }, { id: 0, name: 'Tidak Aktif' }],
      modalVisible: false,
    }
  },
  created() {
    this.getVoucherById()
    this.getTestLabTypeList()
  },
  methods: {
    async getVoucherById() {
      // init form field
      this.form.getFieldDecorator('code', { initialValue: '' })
      this.form.getFieldDecorator('status', { initialValue: '' })
      this.form.getFieldDecorator('testLabTypeId', { initialValue: '' })

      // get data
      const res = await getById(this.$route.params.id)

      // set loading false
      this.isLoading = false

      if (res) {
        // set form data
        this.form.setFieldsValue({ code: res.code })
        this.form.setFieldsValue({ status: res.status })
        this.form.setFieldsValue({ testLabTypeId: res.testLabType.id })
      }
    },
    async getTestLabTypeList() {
      const res = await getAll()
      this.testlabTypeList = res
    },
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields(async (err, values) => {
        if (!err) {
          this.submitting = true
          console.log('Received values of form: ', values)
          try {
            const res = await editById(this.$route.params.id, values)
            if (res && res.data) {
              notification.success({
                message: 'Sukses',
                description: 'Data berhasil disimpan',
                duration: 5,
              })
              router.push('/voucher')
            }
            this.submitting = false
          } catch (e) {
            this.submitting = false
          }
        }
      })
    },
    async handleDelete() {
      const res = await deleteById(this.$route.params.id)
      if (res && res.data) {
        notification.success({
          message: 'Sukses',
          description: 'Data berhasil dihapus',
          duration: 5,
        })
        router.push('/voucher')
      } else {
        notification.warning({
          message: 'Gagal',
          description: 'Data gagal dihapus',
          duration: 5,
        })
      }
      this.submitting = false
      this.modalVisible = false
    },
    handleCancel(e) {
      e.preventDefault()
      router.push('/voucher')
    },
    setModalVisible(modalVisible) {
      this.modalVisible = modalVisible
    },
  },
}
</script>
